.projects-page-section {
    background-color: #f5e5de;
    color: #d08879 !important;
    padding-bottom: 0rem !important;
}
.projects-pp-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 7rem;
    padding: 5rem 0;
}
.project-wrapper {
    display: flex;
    flex-direction: column;
    gap: 3rem;
}
.p-text-wrapper {
    margin: auto 0;
}

.p-text-wrapper h1 {
    text-align: unset;
}
.p-text-wrapper .p-description {
    font-size: 1.25rem;
    color: black !important;
}
.image-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.project-img {
    max-width: 100%;
    max-height: 29rem;
}
.project-img:hover {
    cursor: pointer;
}
.project-img-1 {
    max-height: 27rem;
}
.projects-page-section .cta-section {
    background-color: #f5e5de;
    padding-top: 2rem;
    padding-bottom: 0;
}
.projects-page-section .cta-button {
    max-width: 14rem !important;
}

@media screen and (min-width: 64rem) {
    .page-title {
        font-size: 3rem;
    }
    .project-wrapper {
        justify-content: space-between;
        flex-direction: row !important;
    }
    .project-wrapper:nth-child(even) {
        flex-direction: row-reverse !important;
    }
    .image-container {
        flex-wrap: nowrap;
        justify-content: unset;
    }
    .p-text-wrapper {
        flex-basis: 42%;
    }
    #project-2 .image-container {
        margin: auto 0;
    }
    #project-2 .project-img-2 {
        max-width: 100%;
        max-height: 18rem;
        cursor: unset;
    }
    #project-4 .image-container {
        flex-basis: 48%;
    }
    #project-4 .project-img-4 {
        width: 100%;
        max-height: 36rem;
        cursor: unset;
    }
    #project-5 .project-img-5, #project-5 .project-img-extra-5 {
        max-height: 28rem;
    }
    #project-5 .p-text-wrapper-5 {
        flex-basis: 47%;
    }
}