@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap');

* {
  font-family: "EB Garamond";
}
.section {
    background-color: #fdf9f8;
    padding: 5rem 0;
}

h1 {
    text-align: center;
}

.cta-button {
    background-color: #d08879 !important;
    color: white !important;
    border: none !important;
    font-weight: bold !important;
    font-size: calc(1rem + 0.2vw) !important;
    max-width: 15rem !important;
    margin-top: 2rem !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 0.5rem !important;
    text-transform: uppercase !important;
    border-radius: 999999px !important;
    letter-spacing: 0.75px;
  }
  .cta-button:hover {
    transform: scale(1.02);
  }
  .cta-icon {
    fill: #ffffff !important;
  }

@media screen and (max-width: 31.25rem) {
  h2 {
      text-align: center !important;
  }
}

@media screen and (min-width: 75rem) {
    .section {
        padding: 4rem 0;
    }
}