nav {
  font-size: 1.5rem !important;
  background-color: #f5e5de;
}
.navbar-container {
  padding-top: 2.75rem;
  padding-bottom: 2.75rem;
}
.brand {
  font-size: 1.5rem !important;
  color: #2e2725 !important;
  font-weight: 500;
}
.link-item {
  color: #d08879 !important;
}
.brand:hover:hover {
    color: #d08879 !important;
}
.active-link {
  border-bottom: 1px solid #d08879 !important;
  padding-bottom: 3px !important;
}
.navbar-nav {
  column-gap: 2rem;
}
