.experiences-section {
    padding: 0;
}
.experience-header {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    flex-wrap: wrap;
    padding-bottom: 2rem;
}
.experience-header-body {
    max-width: 40rem;
}
.experience-body {
    padding: 5rem 0 2.5rem;
}
.experiences-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 5rem;
    justify-content: center;
    padding-bottom: 4rem;
  }
.experience-card {
  width: 22rem;
  text-align: center;
}
.experience-img {
  width: 100%;
  height: 18rem;
}
.experience-card-body {
  padding-top: 2rem;
}
.experience-title {
  font-size: 1.75rem;
}
.experience-description {
  font-size: 1.45rem;
  padding-top: 1rem;
  color: black;
}
.experiences-section-btn .view-projects-btn {
  margin: auto;
}
.experiences-section-btn .view-projects-btn:hover {
  transform: scale(1.02);
}

@media screen and (min-width: 75rem) {
    .experiences-wrapper {
        gap: 1rem;
        justify-content: space-between;
    }
}
@media screen and (max-width: 75rem) and (min-width: 62rem) {
    .experience-header h1 {
        width: 15%;
    }
}

@media screen and (max-width: 61.9375rem) {
    .experience-header {
      justify-content: center;
      text-align: center;
    }
  }