#hero {
  background-color: #f5e5de;
}
.hero-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  padding-bottom: 3rem;
}
.profile-img {
  width: 220px;
  height: 210px;
}
@media screen and (min-width: 1200px) {
  .hero-title {
    font-size: 2.8125rem;
  }
}
