.footer-section {
    padding-bottom: 3rem;
    background-color: #f5e5de;
}
.footer-container {
    font-size: 1.25rem;
}
.footer-nav {
    justify-content: center;
    gap: 1rem;
}
.footer-text-wrapper {
    text-align: center;
    padding: 2rem 0;
}
.footer-text {
    color: black !important;
    overflow-wrap: break-word;
}
.social-media-wrapper {
    text-align: center;
}
.social-icon:hover {
    cursor: pointer;
    transform: scale(1.2);
}