.about-container {
  display: flex;
  flex-direction: column;
  row-gap: 4rem;
}
.about-subsection {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 2rem;
}
.subsection-body {
  max-width: 40rem;
}

@media screen and (max-width: 61.9375rem) {
  .about-subsection {
    justify-content: center;
    text-align: center;
  }
}
