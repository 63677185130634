.contact-section {
  background-color: #f5e5de;
  color: black !important;
}
.contact-section h1 {
  color: #d08879 !important;
}
.contact-section textarea {
  height: 15rem;
}
.contact-form-wrapper {
  padding-top: 3rem;
  margin: auto;
  max-width: 35rem;
  font-size: calc(1rem + 0.2vw) !important;
}
.contact-form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.contact-form .form-item {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-item input,
textarea {
  padding: 0.75rem;
  border: none;
  border-radius: 10px;
}
.contact-btn {
  width: 14rem;
  margin: auto;
  border: none;
  color: white;
  border-radius: 999999px;
  font-size: calc(1rem + 0.2vw) !important;
  letter-spacing: 0.75px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 0.5rem 1rem;
}
.contact-btn:hover {
  transform: scale(1.02);
}
.contact-section .footer-section {
  padding-bottom: 0;
}
.social-icon {
  color: #d08879;
}
@media screen and (max-width: 245px) {
    .contact-btn {
        max-width: 14rem !important;
        margin: unset;
    }
}