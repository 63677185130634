.cta-section {
  padding-bottom: 4rem;
  padding-top: 3rem;
}
.cta-section-btn .cta-btn {
  margin: auto;
}
.cta-section-btn .cta-btn:hover {
  margin: auto;
  transform: scale(1.02);
}
