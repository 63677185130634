.skills-section {
  padding: 0;
}
.skills-certs-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
}
.certs-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
}
.cert {
  display: flex;
  color: black;
}
.cert-icon {
  padding-right: 10px;
  color: #d08879;
}
.new-line {
    display: none;
}
.skills-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
  row-gap: 2rem;
}
.skill-card {
  width: 9rem;
  height: 4rem !important;
}
.img-box {
    display: grid;
}
.skills-img {
  transform: rotate(-90deg);
  max-width: 80%;
  max-height: 100vh;
}
@media screen and (min-width: 75rem) {
    .skills-certs-wrapper {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
    }
    .skills-certs-wrapper h1 {
        text-align: left;
    }
    .new-line {
        display:inline;
    }
    .cert {
        font-size: 1.5rem;
    }
    .skills-img {
        max-width: 30rem;
        max-height: unset;
        margin-right: 3rem;
    }
}
@media screen and (max-width: 75rem) {
    .skills-certs-wrapper {
        padding: 4rem 0 .5rem;
    }
    .skills-img {
        margin: 0 auto
    }
}